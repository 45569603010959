<template>
  <div class="variants">
    <h3 class="text-base text-yellow font-semibold mdmax:text-sm">
      {{ programWebDetail.layoutType === 'CLASS_SCHEDULE_LAYOUT' ? `${$t('programDetails.fee.title')}` : `${$t('programDetails.fee.titleShort')}` }}
    </h3>
    <h2 class="text-2xl font-bold mb-6 mdmax:text-xl">
      {{ $t('programDetails.fee.registerNow') }}
    </h2>
    <div :class="[`tab mb-6 flex whitespace-nowrap overflow-x-auto`]">
      <div
        class="cursor-pointer flex-1 font-bold text-lg mdmax:text-base mdmax:px-4 text-center transition-all duration-200 px-6 py-2 border-b-4"
        :class="selectedTab?.variantId === variant?.variantId ? 'active-tab text-black' : 'text-neutral-300 border-transparent'"
        @click="selectTab(variant)"
        v-for="variant in coreClassVariants"
        :key="variant.variantId"
      >
        {{ variant.variantName }} TES
      </div>
    </div>
    <div class="tab-content flex w-full mb-12 mdmax:flex-col">
      <div :class="[`flex-shrink-0 ${expandPrice ? 'w-full' : 'w-80 mdmax:w-full'}`]">
        <div :class="[`p-6 bg-yellow-100 rounded-lg shadow-soft flex ${expandPrice ? 'w-full mdmax:flex-col' : 'flex-col'}`]">
          <template v-if="!isLoading">
            <div :class="[`price ${expandPrice ? 'flex-1' : ''}`]">
              <p class="text-center mb-2 text-lg">{{ $t('programDetails.fee.normalPrice') }}</p>
              <p :class="[`text-2xl font-bold text-center ${variantDetail.promo ? 'strike-red' : ''} `]">{{ variantDetail.price | currencyFormat }}</p>
            </div>
            <div :class="[`promo-price border-yellow ${expandPrice ? 'flex-1 border-l mdmax:border-r-0 mdmax:border-b mdmax:pb-4 mdmax:mb-4' : 'border-b mb-4 pb-4'}`]" v-if="variantDetail.promo">
              <p class="text-lg font-bold text-yellow text-center mb-2">{{ variantDetail.promoLabel || $t('programDetails.fee.earlyBirdPromo') }}</p>
              <p class="text-2xl font-bold text-center">{{ variantDetail.promo | currencyFormat }}</p>
            </div>
          </template>
          <template v-else>
            <div class="flex w-full justify-center items-center h-40">
              <Loading />
            </div>
          </template>
        </div>
      </div>
      <div class="pl-6 w-full mdmax:pl-3 mdmax:pr-3 mdmax:pt-10 mdmax:flex mdmax:whitespace-nowrap mdmax:overflow-x-auto" v-if="programWebDetail.layoutType === 'CLASS_SCHEDULE_LAYOUT'">
        <template v-if="variantList?.length">
          <div class="schedule rounded-lg shadow-small p-6 mb-4 mdmax:mb-2 mdmax:mr-4 last:mr-0 mdmax:max-w-xs" v-for="schedule in variantList" :key="schedule.id">
            <p class="font-bold text-lg mb-4 mdmax:whitespace-normal">{{ schedule.nama }}</p>
            <div class="mb-2 leading-0" v-if="schedule.start_date">
              <div class="inline-grid gap-2 grid-flow-col place-items-center">
                <Calendar width="24" height="24" color="yellow" />
                <p class="text-sm font-bold">{{ schedule.start_date | formatDate }}</p>
              </div>
            </div>
            <div class="leading-0 mb-2" v-if="variantDetail.duration">
              <div class="inline-grid gap-2 grid-flow-col place-items-center">
                <TimeOutline color="yellow" width="24" height="24" />
                <p class="text-sm font-medium">{{ variantDetail.duration }}</p>
              </div>
            </div>
            <div class="leading-0 mb-4" v-if="variantDetail.location">
              <div class="inline-grid gap-2 grid-flow-col place-items-center">
                <location width="24" height="24" color="yellow" />
                <p class="text-sm font-medium">{{ variantDetail.location }}</p>
              </div>
            </div>
            <div class="flex w-full items-center">
              <div class="bg-grey-lighter rounded-lg py-2 px-4 text-sm font-medium w-full mr-4">{{ variantDetail.weeklySchedule }}</div>
              <Button buttonText="Register" @action="registerWithVariant()" />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { currencyFormat, formatDate } from '@/utils'
export default {
  name: 'variants',
  components: {
    Loading: () => import(/* webpackChunkName: "icon" */ '@/components/illustration/Loading'),
    Calendar: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Date'),
    TimeOutline: () => import(/* webpackChunkName: "icon" */ '@/components/icons/TimeOutline'),
    Location: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Location'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  filters: {
    currencyFormat,
    formatDate
  },
  props: {
    programWebDetail: {
      type: Object,
      default: () => {}
    },
    coreClassVariants: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedTab: null,
      isLoading: true
    }
  },
  computed: {
    ...mapGetters('program', ['variantDetail']),
    variantList() {
      return this.variantDetail.lmsClasses
    },
    expandPrice() {
      let valid = false
      if (this.programWebDetail.layoutType === 'CLASS_SCHEDULE_LAYOUT') {
        if (this.variantList?.length) {
          valid = false
        } else {
          valid = true
        }
      }
      return valid
    }
  },
  mounted() {
    this.initComponent()
  },
  watch: {
    selectedTab() {
      this.getVariantInfo()
    }
  },
  methods: {
    ...mapActions('program', ['getVariantDetail']),
    registerWithVariant() {
      this.$emit('selectVariant', this.selectedTab.variantId)
    },
    initComponent() {
      this.selectedTab = this.coreClassVariants.length ? this.coreClassVariants[0] : {}
    },
    selectTab(variant) {
      this.selectedTab = variant
    },
    getVariantInfo() {
      this.isLoading = true
      this.getVariantDetail({
        variantId: this.selectedTab.variantId,
        params: {
          page: 0,
          size: 2,
          sortBy: 'startAt',
          direction: 'ASC',
          upcoming: true
        }
      }).then(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.strike-red {
  text-decoration: line-through;
  text-decoration-color: #d63c3c;
}
.active-tab {
  border-color: #3b82f6;
}
</style>
